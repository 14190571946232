.component-button-panel {
  background-color: #858694;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1 0 auto;
}

.component-button-panel > div {
  width: 100%;
  margin-bottom: 1px;
  flex: 1 0 auto;
  display: flex;
}
